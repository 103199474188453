"use client";

import React, { useState, useEffect } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { themeConfigColors } from "@/theme/theme-config";

const EnhancedCookieConsent: React.FC = () => {
  const [consent, setConsent] = useState(false);
  useEffect(() => {
    const data = getCookieConsentValue();
    setConsent(!data || data == "false");
  }, []);
  return (
    <>
      {consent && (
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          enableDeclineButton
          buttonStyle={{
            borderRadius: "6px",
            background: themeConfigColors.primary,
            color: "#ffff",
          }}
          declineButtonStyle={{
            borderRadius: "6px",
            background: themeConfigColors.error,
          }}
          overlay={false}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      )}
    </>
  );
};

export default EnhancedCookieConsent;
