"use client";

import "./globals.scss";
import "antd/dist/reset.css";
import { Inter_Tight } from "next/font/google";
import { ConfigProvider, Empty } from "@/lib/antd";
import { ThemeProvider, useTheme } from "@/contexts/themeContext/themeContext";
import StyledComponentsRegistry from "@/lib/antd-registry";
import EnhancedCookieConsent from "@/components/consent/consent";

const inter = Inter_Tight({ subsets: ["latin"] });

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <ThemeProvider>
        <ThemedApp>{children}</ThemedApp>
      </ThemeProvider>
    </html>
  );
}

const ThemedApp = ({ children }: { children: React.ReactNode }) => {
  const { themeConfig, themeConfigColors, mode } = useTheme();

  return (
    <body
      style={{ backgroundColor: themeConfigColors.background }}
      className={inter.className}
    >
      <EnhancedCookieConsent />
      <StyledComponentsRegistry>
        <ConfigProvider
          theme={themeConfig}
        >
          {children}
        </ConfigProvider>
      </StyledComponentsRegistry>
    </body>
  );
};
