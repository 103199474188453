'use client';

import React, {useState} from 'react';
import { StyleProvider, createCache, extractStyle } from '@ant-design/cssinjs';
import { useServerInsertedHTML } from 'next/navigation';

const StyledComponentsRegistry = ({ children }: { children: React.ReactNode }) => {
  const [cache] = useState(() => createCache());
  const render = <>{children}</>;

  useServerInsertedHTML(() => {
    return <script
      dangerouslySetInnerHTML={{
        __html: `</script>${extractStyle(cache)}<script>`,
      }}
    />;
  });

  if (typeof window !== 'undefined') {
    return render;
  }
  
  return <StyleProvider cache={cache} hashPriority="high">{render}</StyleProvider>;
};

export default StyledComponentsRegistry;